import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header>
      <Link to="/">
        <img className="logo" src="/images/logo2.png" alt="" />
      </Link>

      <div className="d-flex justify-content-around">
        <a href="/blog/blog.html" target="_blank">
          <button className="blog-link">Блог</button>
        </a>
      </div>
    </header>
  );
};
